<template>
  <div>
    <b-table
      :data="commands"
      :loading="isLoading"
      paginated
      per-page="15"
    >
      <b-table-column
        field="title"
        label="Title"
        v-slot="props"
        searchable
        sortable
      >
        {{ props.row.title }}
      </b-table-column>
      <b-table-column
        field="artist"
        label="Artist"
        v-slot="props"
        searchable
        sortable
      >
        {{ props.row.artist }}
      </b-table-column>
      <b-table-column
        field="artist"
        label="Request Text"
        v-slot="props"
      >
        <b-input
          :value="getRequestText(props.row)"
          size="is-small"
        />
      </b-table-column>
      <b-table-column
        field="artist"
        v-slot="props"
      >
        <b-button
          size="is-small"
          @click="copy(props.row)"
        >
          Copy
        </b-button>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    channel: String
  },
  data: function () {
    return {
      commands: [],
      isLoading: true
    }
  },

  methods: {
    getRequestText (song) {
      return `!request ${song.title} - ${song.artist}`
    },
    loadData () {
      this.isLoading = true
      return this.$axios.get(`/api/u1/${this.channel}/request`)
        .then(res => {
          this.isLoading = false
          this.commands = res.data
        })
        .catch(err => {
          this.isLoading = false
          this.$buefy.toast.open(err.response.message)
        })
    },
    copy (song) {
      navigator.clipboard.writeText(this.getRequestText(song))
      this.$buefy.toast.open('Copied to clipboard')
    }
  },
  mounted: function () {
    this.loadData()
  }
}

</script>

<style>
</style>
