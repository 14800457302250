<template>
  <b-table
    :data="songs"
    :striped="true"
    :hoverable="true"
    :loading="false"
    detailed
    detail-key="id"
  >
    <b-table-column
      field="title"
      label="Title"
      v-slot="props"
    >
      <a @click="props.toggleDetails(props.row)">
        {{ props.row.title }}
      </a>
    </b-table-column>

    <b-table-column
      field="artist"
      label="Artist"
      v-slot="props"
    >
      {{ props.row.artist }}
    </b-table-column>
    <b-table-column
      field="chordChart"
      label="Chord Chart"
      v-slot="props"
    >
      <template v-if="props.row.chordChart">
        <a :href="props.row.chordChart" target="_blank">Chords</a>
      </template>
      <template v-else>
        :(
      </template>
    </b-table-column>
    <b-table-column
      field="requestedBy"
      label="Requested By"
      v-slot="props"
    >
      {{ props.row.requestedBy }}
    </b-table-column>
    <b-table-column
      field="requestText"
      label="RequestText"
      v-slot="props"
    >
      {{ props.row.requestText }}
    </b-table-column>
    <b-table-column
      field="approved"
      label="Approved"
      v-slot="props"
    >
      <b-button
        @click="onApprove(props.row.id)"
        :class="!props.row.approved ? 'is-success' : ''" :disabled="props.row.approved"
      >
        {{ props.row.approved ? 'Approved' : 'Approve'}}
      </b-button>
    </b-table-column>
    <b-table-column
      field="save"
      label="Save"
      v-slot="props"
    >
      <b-button @click="onSave(props.row.id)" class="is-success">Save</b-button>
    </b-table-column>
    <b-table-column
      field="remove"
      label="Remove"
      v-slot="props"
    >
      <b-button @click="onDelete(props.row.id)" class="is-danger">Remove</b-button>
    </b-table-column>

    <template #empty>
      <div class="has-text-centered">No records</div>
    </template>
    <template #detail="props">
      {{ props.row.notes }}
    </template>
  </b-table>
</template>

<script>
export default {
  props: [
    'songs',
    'canEdit',
    'onSave',
    'onDelete',
    'onApprove'
  ]
}
</script>
