<template>
  <div>
    <b-collapse class="card" animation="slide" :open="false">
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          aria-controls="contentIdForA11y3"
        >
          <p class="card-header-title">
            {{header}}
          </p>
          <a class="card-header-icon">
            <b-icon
              :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <b-input
            v-model="data[idx]"
            v-for="(item, idx) in data"
            :key="`${header}-${idx}`"
            :icon-right="idx > 0 ? 'delete' : ''"
            :icon-right-clickable="idx > 0"
            @icon-right-click="deleteRow(idx)"
          ></b-input>
        </div>
      </div>
      <footer class="card-footer">
        <a
          class="card-footer-item is-success"
          @click="addRow"
          v-if="!deleteMode"
        >
          Add Row
        </a>
        <a
          class="card-footer-item"
          @click="deleteMode = true"
          v-if="!deleteMode && deleteMe"
        >
          Delete
        </a>
        <a
          class="card-footer-item"
          @click="deleteMode = false"
          v-if="deleteMode"
        >
          Cancel
        </a>
        <a
          class="card-footer-item"
          @click="deleteMe"
          v-if="deleteMode"
        >
          Confirm
        </a>
      </footer>
    </b-collapse>

  </div>
</template>

<script>
export default {
  props: [
    'header',
    'data',
    'deleteMe'
  ],
  data: function () {
    return {
      deleteMode: false
    }
  },
  methods: {
    deleteRow (idx) {
      this.data.splice(idx, 1)
    },
    addRow () {
      this.data.push('')
    }
  }
}
</script>
