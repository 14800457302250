<template>
  <div class="container">
    <div class="box">
      <h1 class="title">Login</h1>
      <div class="container">
        Hi! You're not logged in to Swamp Emu yet!
      </div>
      <a href="/api/auth/twitch">
        <b-button
          size="is-large"
          type="is-primary"
          icon-left="twitch"
        >
          Login using Twitch
        </b-button>
      </a>

      <form action="/api/auth/login" method="post" v-if="allowedLogins.indexOf('local') !== -1">
        <label for="fname">Username:</label><br>
        <input type="text" id="username" name="username"><br>
        <label for="lname">Password:</label><br>
        <input type="text" id="password" name="password">
        <input type="submit" value="Submit">
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data: function () {
    return {
      loginLoaded: false,
      allowedLogins: []
    }
  },
  mounted: function () {
    axios.get('/api/auth/allowed')
      .then(res => {
        this.loginLoaded = true
        this.allowedLogins = res.data
      })
  }
}
</script>
