<template>
  <div class="home">
    <Header />
    <div class="columns">
      <div class="column is-one-quarter">
        <FeatureSettings />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import FeatureSettings from '@/components/settings/FeatureSettings.vue'
export default {
  components: {
    Header,
    FeatureSettings
  },
  data: function () {
    return {
      featuresLoading: true
    }
  }
}
</script>

<style>

</style>
