<template>
  <div class="home">
    <Header />
    <div class="columns">
      <div class="column is-one-quarter">
        <b-field position="is-right">
          <b-button
            label="Refresh"
            type="is-primary"
            @click="loadData"
          />
          <b-button
            label="New Schedule"
            type="is-primary"
            @click="createCommand"
          />
        </b-field>
        <b-table
          :data="commands"
          :loading="isLoading"
          :selected.sync="selected"
          focusable
          paginated
          per-page="15"
        >
          <b-table-column
            field="command"
            label="Schedules"
            width="40"
            v-slot="props"
            searchable
            sortable
          >
            {{ props.row.description }}
          </b-table-column>
        </b-table>
      </div>
      <div class="column">
        <div class="box" v-if="selected">

          <b-field
            label="Description"
            :message="selectedErrors['description'] || ''"
          >
            <b-input
              v-model="selected.description"
            ></b-input>
          </b-field>
          <b-field
            label="Frequency (minutes)"
            :message="selectedErrors['frequency'] || ''"
          >
            <b-input
              v-model="selected.frequency"
            ></b-input>
          </b-field>
          <List
            header="Messages"
            :data="selected.messages"
          ></List>

          <div v-if="selected.id != deleting">
            <b-button
              :loading="saving[selected.id]"
              label="Save"
              type="is-info"
              @click="saveCommand(selected)"
            />
            <b-button
              :loading="saving[selected.id]"
              label="Delete"
              type="is-danger"
              @click="tryDelete(selected)"
            />
          </div>
          <div v-if="selected.id == deleting">
            <b-button
              :loading="saving[selected.id]"
              label="Delete"
              type="is-danger"
              @click="deleteCommand(selected)"
            />
            <b-button
              :loading="saving[selected.id]"
              label="Cancel"
              type="is-info"
              @click="untryDelete(selected)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import List from '@/components/List.vue'

export default {
  name: 'Home',
  components: {
    Header,
    List
  },
  data: function () {
    return {
      isLoading: false,
      loading: true,
      commands: [],
      selected: null,
      newCommand: {
        data: '',
        error: ''
      },
      newRow: {
        data: '',
        error: ''
      },
      saving: {

      },
      errors: {

      },
      deleting: ''
    }
  },
  methods: {
    loadData: function () {
      this.isLoading = true
      return this.$axios.request('/api/v1/scheduler')
        .then(res => {
          this.isLoading = false
          this.commands = res.data
        })
        .catch(err => {
          this.isLoading = false
          this.$buefy.toast.open(err.response.message)
        })
    },
    createCommand: function () {
      this.$set(this.saving, 'newCommand', false)
      this.$set(this.errors, 'newCommand', {})
      this.deleted = ''
      console.log(this.selected)
      this.$set(this, 'selected', {
        messages: ['!behave'],
        description: '',
        frequency: 10
      })
    },
    saveCommand: function (command) {
      console.log(command)
      this.$set(this.saving, command.id, true)
      this.$set(this.errors, command.id, {})
      const n = Object.assign({}, command)
      n.frequency = parseInt(n.frequency)
      return this.$axios.put('/api/v1/scheduler', n)
        .then((res) => {
          this.$buefy.toast.open('Scheduler updated')
          this.saving[command.id] = false
          return this.loadData()
        })
        .catch(err => {
          if (err.response) {
            const e = err.response.data.errors.reduce((p, n) => {
              p[n.field] = n.message
              return p
            }, {})
            this.$set(this.errors, command.id, e)
            this.saving[command.id] = false
          }
        })
    },
    addNewCommand: function () {
      const newCmd = this.newCommand.data.toLowerCase().trim()
      this.newCommand.error = ''
      if (newCmd.length === 0) {
        this.newCommand.error = 'Command must have at least one character'
        return
      }
      this.selected.command.push(newCmd)
      this.newCommand.data = ''
    },
    removeCommand: function (val) {
      this.newCommand.error = ''
      if (this.selected.command.length === 1) {
        this.newCommand.error = 'Can\'t remove the last message'
      } else {
        this.newCommand.data = ''
        this.selected.command.splice(val, 1)
      }
    },
    addNewRow: function () {
      const newRow = this.newRow.data.trim()
      this.newRow.error = ''
      if (newRow.length === 0) {
        this.newRow.error = 'Row name cannot be empty'
      } else if (!newRow.match(/^[A-Za-z0-9]+$/)) {
        this.newRow.error = 'Row name can only contain letters and numbers'
      } else {
        this.$set(this.selected.messages, newRow, [''])
      }
    },
    deleteInstruction: function (instr) {
      return () => {
        this.$delete(this.selected.messages, instr)
      }
    },
    tryDelete: function () {
      this.deleting = this.selected.id
    },
    untryDelete: function () {
      this.deleting = ''
    },
    deleteCommand: function (command) {
      this.$set(this.saving, command.id, true)
      this.$set(this.errors, command.id, {})
      return this.$axios.delete(`/api/v1/scheduler/${command.id}`)
        .then(res => {
          this.$buefy.toast.open('Command deleted')
          this.saving[command.id] = false
          this.commands = this.commands.filter(v => v.id !== command.id)
          this.selected = null
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response)
          }
        })
    }
  },
  computed: {
    selectedErrors: function () {
      if (!this.selected) {
        return {}
      } else if (!this.errors[this.selected.id]) {
        return {}
      } else {
        return this.errors[this.selected.id]
      }
    }
  },
  mounted: function () {
    this.loadData()
  }
}
</script>
