<template>
  <div>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
    <b-field grouped position="is-right">
      <p class="control">
        <b-button
          :loading="isLoading"
          label="Refresh"
          type="is-info"
          @click="loadSongs"
        />
      </p>
    </b-field>
    <SavedSongDisplay
      :songs="saved"
      :onSave="onSave"
      :onDelete="onDelete"
      :get="getSong"
      :onRequest="onRequest"
    />
  </div>
</template>

<script>
import SavedSongDisplay from '@/components/requests/SavedSongDisplay'
export default {
  components: {
    SavedSongDisplay
  },
  data: function () {
    return {
      saved: [],
      isLoading: true
    }
  },
  methods: {
    loadSongs: function () {
      this.isLoading = true
      this.$axios.get('api/v1/request')
        .then(result => {
          const data = result.data
          this.saved = data.saved
          this.isLoading = false
        })
    },
    getSong: function (id) {
      return this.saved.filter(u => u.id === id)[0]
    },
    onSave: function (id) {
      console.log(`save ${id}`)
      return this.$axios.put('api/v1/request/saved', this.getSong(id)).then(v => {
        this.$buefy.toast.open(`${v.data.fullTitle} saved`)
      })
    },
    onDelete: function (id) {
      this.isLoading = true
      return this.$axios.delete(`api/v1/request/saved/${id}`).then(v => {
        this.loadSongs()
      })
    },
    onRequest: function (id) {
      this.loading = true
      return this.$axios.put(`api/v1/request/saved/${id}/request`).then(v => {
        this.$buefy.toast.open(`${v.data}`)
        this.loaded = true
      })
    }
  },
  mounted () {
    this.loadSongs()
  }
}
</script>
