<template>
  <div class="home">
    <Header />
    Hi there :)
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header'
export default {
  name: 'Home',
  components: {
    Header
  }
}
</script>
