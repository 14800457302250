<template>
  <div :style="globalStyle()" class="mainWrapper">
    <CurrentRequest
      :settings="settings.current"
      :current="requestList[0]"
      :next="requestList[1]"
    />
  </div>
</template>

<script>
import CurrentRequest from '@/components/requests/CurrentRequest.vue'
export default {
  components: {
    CurrentRequest
  },
  data: function () {
    return {
      requestList: [],
      settings: {
        colourKey: 'green',
        current: {
          position: {
            left: 10,
            top: 920,
            width: 950,
            height: 150
          },
          style: {
            border: '5px solid #8b000050',
            'border-radius': '8px',
            background: 'darkorange',
            color: 'black'
          }
        }
      }
    }
  },
  methods: {
    globalStyle: function () {
      return {
        background: this.settings.colourKey
      }
    },
    loadRequests: function () {
      this.$axios.get('/api/v1/request')
        .then(result => {
          const data = result.data
          this.requestList = data.requests.filter(v => v.approved)
        })
    }
  },
  timers: {
    loadRequests: { time: 15000, autostart: true, repeat: true }
  }
}
</script>

<style>
.mainWrapper {
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}
</style>
