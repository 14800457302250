<template>
  <div class="requestWrapper" :style="wrapperStyle()">
    <div class="currently-playing">
      CURRENTLY PLAYING
    </div>
    <div class="request-title">
      {{ current ? current.title : 'No songs in the queue at the moment.' }}
    </div>
    <div class="request-artist">
      {{ current ? current.artist : 'Use the !request command?'}}
    </div>
    <div class="next-up">
      Next up: {{ next ? next.fullTitle : '??? Use the !request command?'}}
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'current',
    'settings',
    'next'
  ],
  methods: {
    wrapperStyle: function () {
      const { position, style } = this.settings
      return Object.assign({
        left: `${position.left}px`,
        top: `${position.top}px`,
        width: `${position.width}px`,
        height: `${position.height}px`
      }, style)
    }
  }
}
</script>

<style scoped>
.requestWrapper {
  position: absolute;
  border: 1px solid black;
  padding-left: 15px;
}
.request-title {
  padding-top: 10px;
  font-size: 3.25em;
}
.request-artist {
  margin-top: -25px;
  font-size: 1.5em;
}
.currently-playing {
  position: absolute;
  font-size: 1.3em;
  font-weight: bold;
}
.next-up {
  font-size: 1.5em;
  position: absolute;
  bottom: 5px;
}
</style>
