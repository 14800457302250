<template>
  <b-table
    :data="songs"
    :striped="true"
    :hoverable="true"
    :loading="false"
    detailed
    detail-key="id"
  >
    <b-table-column
      field="title"
      label="Title"
      v-slot="props"
      searchable
      sortable
      :custom-sort="fieldSort('title')"
    >
      <a @click="props.toggleDetails(props.row)">
        {{ props.row.title }}
      </a>
    </b-table-column>

    <b-table-column
      field="artist"
      label="Artist"
      v-slot="props"
      searchable
      sortable
      :custom-sort="fieldSort('artist')"
    >
      {{ props.row.artist }}
    </b-table-column>
    <b-table-column
      field="chordChart"
      label="Chord Chart"
      v-slot="props"
      sortable
    >
      <template v-if="props.row.chordChart">
        <a :href="props.row.chordChart" target="_blank">Chords</a>
      </template>
      <template v-else>
        :(
      </template>
    </b-table-column>
    <b-table-column
      field="requestSong"
      label=""
      v-slot="props"
    >
      <b-button
        @click="doRequest(props.row.id)"
        :loading="isLoading(props.row.id)"
      >
        Request
      </b-button>
    </b-table-column>
    <b-table-column
      field="autoApprove"
      label="Auto Approve"
      v-slot="props"
      sortable
    >
      {{ props.row.autoApprove ? 'Yes' : 'No' }}
    </b-table-column>

    <template #empty>
      <div class="has-text-centered">No records</div>
    </template>
    <template #detail="props">
      <b-field>
        <b-checkbox v-model="get(props.row.id).autoApprove">Auto-Approve?</b-checkbox>
      </b-field>
      <b-field label="Chord Chart">
        <b-input v-model="get(props.row.id).chordChart"></b-input>
      </b-field>
      <b-field label="Notes">
        <b-input maxlength="200" type="textarea" v-model="get(props.row.id).notes"></b-input>
      </b-field>
      <b-field>
        <b-checkbox v-model="get(props.row.id).autoDeny">Auto-Deny?</b-checkbox>
      </b-field>
      <b-field label="Auto-Deny Message">
        <b-input v-model="get(props.row.id).autoDenyMessage"></b-input>
      </b-field>
      <b-button
        @click="doSave(props.row.id)"
        :loading="isLoading(props.row.id)"
      >
        Save
      </b-button>
    </template>
  </b-table>
</template>

<script>
export default {
  props: [
    'songs',
    'canEdit',
    'onSave',
    'onDelete',
    'onRequest',
    'get'
  ],
  data: function () {
    return {
      loading: []
    }
  },
  methods: {
    isLoading: function (id) {
      return this.loading.indexOf(id) > -1
    },
    sortNoCase: function (a, b, asc) {
      const aa = a.toLowerCase()
      const bb = b.toLowerCase()
      return asc ? aa.localeCompare(bb) : bb.localeCompare(aa)
    },
    fieldSort: function (field) {
      return (a, b, asc) => {
        return this.sortNoCase(a[field], b[field], asc)
      }
    },
    doSave: function (id) {
      this.loading.push(id)
      this.onSave(id).then(v => {
        this.loading.splice(this.loading.indexOf(id), 1)
      })
    },
    doRequest: function (id) {
      this.loading.push(id)
      this.onRequest(id).then(v => {
        this.loading.splice(this.loading.indexOf(id), 1)
      })
    }
  }
}
</script>
