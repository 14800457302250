import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    features: []
  },
  mutations: {
    setFeatures (state, val) {
      state.features = val
    }
  },
  actions: {
  },
  modules: {
  }
})
