import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Commands from '../views/Commands.vue'
import Settings from '@/views/admin/Settings.vue'
import Requests from '@/views/Requests.vue'
import RequestStreamView from '@/views/RequestStreamView.vue'
import ChannelView from '@/views/no-auth/Channel'
import Scheduler from '@/views/admin/Schedule'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/admin/commands',
    name: 'Commands',
    component: Commands
  },
  {
    path: '/admin/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/admin/requests',
    name: 'Requests',
    component: Requests
  },
  {
    path: '/admin/scheduler',
    name: 'Scheduler',
    component: Scheduler
  },
  {
    path: '/admin/requests/stream',
    name: 'RequestStreamView',
    component: RequestStreamView
  },
  {
    path: '/:channel',
    name: 'ChannelView',
    component: ChannelView
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
