<template>
  <div>
    <Header />
    <div >
    <b-tabs v-model="activeTab">
      <b-tab-item label="Requests">
        <RequestTab />
      </b-tab-item>
      <b-tab-item label="Saved Songs">
        <SavedSongsTab />
      </b-tab-item>
      <b-tab-item label="Stream View">
        <StreamSettings />
      </b-tab-item>
    </b-tabs>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
import RequestTab from '@/components/requests/RequestTab'
import SavedSongsTab from '@/components/requests/SavedSongsTab'
import StreamSettings from '@/components/requests/StreamSettings'
export default {
  components: {
    Header,
    RequestTab,
    StreamSettings,
    SavedSongsTab
  },
  data: function () {
    return {
      activeTab: 0
    }
  },
  methods: {
    loadRequests: function () {
      this.$axios.get('api/v1/request')
        .then(result => {
          const data = result.data
          this.requests = data.requests
        })
    }

  }
}
</script>
