<template>
  <div>
    <b-field grouped group-multiline>
      <div class="control">
        <b-switch v-model="isBordered">Bordered</b-switch>
        <b-button
          to="/requests/stream"
          tag="router-link"
        >Stream View</b-button>
      </div>
    </b-field>
    <div class="resizable">
      <div :style="getBorderStyle()" class="innerResizable">
        Hello
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      current: null,
      next: null,
      isBordered: true,
      settings: {
        keyColour: 'green'
      }
    }
  },
  methods: {
    getBorderStyle () {
      return {
        border: '1px solid black',
        'border-radius': '7px'

      }
    }
  }
}
</script>

<style scoped>
.resizable {
  resize: both;
  overflow: hidden;
  position: relative;
  background: blue;
  padding: 10px;
}

.innerResizable {
  background: red;
  width: 100%;
  height: 100%;
  padding: 10px;
  position: relative;
}
</style>
