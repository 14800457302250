<template>
  <b-table
    :data="features"
    :loading="isLoading"
    paginated
    per-page="15"
  >
    <b-table-column
      field="feature"
      label="Features"
      width="40"
      v-slot="props"
      searchable
      sortable
    >
      {{ props.row.feature }}
    </b-table-column>
    <b-table-column
      field="enabled"
      label="Enabled"
      width="40"
      v-slot="props"
    >
      <b-switch v-model="props.row.enabled" :input="changeSetting(props.row)"></b-switch>
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  data () {
    return {
      isLoading: true,
      features: [],
      storedData: {}
    }
  },
  methods: {
    loadData () {
      this.$axios.get('/api/v1/settings/features')
        .then(v => {
          this.storedData = v.data
          this.features = Object.keys(v.data).map(u => ({ feature: u, enabled: v.data[u] }))
          this.isLoading = false
        })
    },
    changeSetting (feature) {
      if (feature.enabled !== this.storedData[feature.feature]) {
        this.isLoading = true
        const enableText = feature.enabled ? 'enable' : 'disable'
        this.$axios.put(`/api/v1/settings/features/${feature.feature}/${enableText}`)
          .then(res => {
            this.$store.commit('setFeatures', Object.keys(res.data).filter(v => res.data[v]))
            this.storedData = res.data
            this.features = Object.keys(res.data).map(u => ({ feature: u, enabled: res.data[u] }))
            this.isLoading = false
          })
      }
    }
  },
  mounted: function () {
    this.loadData()
  }
}
</script>

<style>

</style>
