<template>
  <div>
    <b-table
      :data="commands"
      :loading="isLoading"
      paginated
      per-page="15"
    >
      <b-table-column
        field="command"
        label="Command"
        width="40"
        v-slot="props"
        searchable
        sortable
      >
        {{ props.row.command }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    channel: String
  },
  data: function () {
    return {
      commands: [],
      isLoading: true
    }
  },

  methods: {
    loadData () {
      this.isLoading = true
      return this.$axios.get(`/api/u1/${this.channel}/commands`)
        .then(res => {
          this.isLoading = false
          this.commands = res.data.map(command => ({ command }))
        })
        .catch(err => {
          this.isLoading = false
          this.$buefy.toast.open(err.response.message)
        })
    }
  },
  mounted: function () {
    this.loadData()
  }
}

</script>

<style>
</style>
