<template>
  <div>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
    <SongDisplay
      :songs="requests"
      :onSave="onSave"
      :onDelete="onDelete"
      :onApprove="onApprove"
    />
  </div>
</template>

<script>
import SongDisplay from '@/components/requests/SongDisplay'
export default {
  components: {
    SongDisplay
  },
  data: function () {
    return {
      requests: [],
      isLoading: true
    }
  },
  methods: {
    loadRequests: function () {
      this.isLoading = true
      this.$axios.get('api/v1/request')
        .then(result => {
          const data = result.data
          this.requests = data.requests
          this.isLoading = false
        })
    },
    getRequest: function (id) {
      return this.requests.filter(u => u.id === id)[0]
    },
    onSave: function (id) {
      console.log(`save ${id}`)
      this.$axios.post('api/v1/request/saved', this.getRequest(id)).then(v => {
        this.$buefy.toast.open(v.data)
      })
    },
    onDelete: function (id) {
      this.isLoading = true
      this.$axios.delete(`api/v1/request/requests/${id}`).then(v => {
        this.loadRequests()
      })
    },
    onApprove: function (id) {
      this.loading = true
      this.$axios.put(`api/v1/request/requests/${id}/approve`).then(v => {
        this.$buefy.toast.open(`Approved request for ${this.getRequest(id).fullTitle}`)
        this.loadRequests()
      })
    }
  },
  mounted () {
    this.loadRequests()
  },
  timers: {
    loadRequests: { time: 15000, autostart: true, repeat: true }
  }
}
</script>
