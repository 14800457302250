<template>
  <div>
    <b-navbar-item >
      <img
        src="https://raw.githubusercontent.com/buefy/buefy/dev/static/img/buefy-logo.png"
        alt="Lightweight UI components for Vue.js based on Bulma"
      />
    </b-navbar-item>
    <div >
      <b-tabs v-model="activeTab">
        <b-tab-item label="Commands">
          <Commands :channel="$route.params.channel" />
        </b-tab-item>
        <b-tab-item label="Requestable Songs">
          <RequestableSongs :channel="$route.params.channel" />
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Commands from '@/components/no-auth/Commands'
import RequestableSongs from '@/components/no-auth/RequestableSongs'
export default {
  components: {
    Commands,
    RequestableSongs
  },
  data: function () {
    return {
      activeTab: 0
    }
  }
}
</script>

<style>

</style>
