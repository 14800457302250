<template>
  <b-navbar>
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/home' }">
        <img
          src="https://raw.githubusercontent.com/buefy/buefy/dev/static/img/buefy-logo.png"
          alt="Lightweight UI components for Vue.js based on Bulma"
        />
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item href="/admin/settings">Settings</b-navbar-item>
      <b-navbar-item href="/admin/commands">Commands</b-navbar-item>
      <b-navbar-item href="/admin/scheduler">Schedule</b-navbar-item>
      <!--b-navbar-item href="/nuzlocke">Nuzlocke</b-navbar-item-->
      <b-navbar-item href="/admin/requests" v-if="hasFeature('request')">Requests</b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <div class="buttons">
          <a class="button is-light">Sign Out</a>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  data: function () {
    return {
      enabledFeatures: []
    }
  },
  methods: {
    loadData: function () {
      this.$axios.get('/api/v1/settings/features')
        .then(res => {
          this.enabledFeatures = Object.keys(res.data).filter(v => res.data[v])
          this.$store.commit('setFeatures', this.enabledFeatures)
        })
    },
    hasFeature: function (f) {
      return this.$store.state.features.indexOf(f) > -1
    }
  },
  mounted: function () {
    this.loadData()
  }
}
</script>
