import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueTimers from 'vue-timers'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import axios from 'axios'

Vue.prototype.$axios = axios.create({
  baseURL: window.location.origin,
  maxRedirects: 0
})

Vue.config.productionTip = false
Vue.use(VueTimers)
Vue.use(Buefy, {
  defaultIconPack: 'mdi'
})

Vue.prototype.$axios.interceptors.response.use((response) => response, (error) => {
  console.log(error)
  if (error.statusCode === 401) {
    router.push('/')
  }
  return Promise.reject(error)
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
